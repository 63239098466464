import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 2rem 0 0.5rem 0;
  background-size: cover;
  background-color: #E5CF03;
  background-position: top;
  background-repeat: no-repeat;
  @media (max-width: 1960px) {
    padding: 2rem 0 0.5rem;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: flex-start;

  @media (max-width: 1100px) {
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
`;

export const Links = styled.div`
  display: flex;
  align-items: center;

  a {
    margin: 0 0.5rem;
   

    img {
      margin: 0;
    }
    &:first-child,
    &:last-child {
      margin: 0;
    }
  }
`;

export const Details = styled.div`
  
  ${(props) => {
    if (props.hasRightMargin) {
      return `margin-right: auto;`
    }
  }}
  
  h2,
  a,
  a:hover {
   color:black;
  },
  span {
    color: #212121;
  }
 
  @media (max-width: 1100px) {
    margin-right: unset;
  }

  @media (max-width: 680px) {
    margin-bottom: 1rem;
  }
`;

export const TextField = styled.p`
  padding-left: 20px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #000;
`;
