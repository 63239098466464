import styled from 'styled-components';

export const Button = styled.button`
  cursor: pointer;
  border-radius: 3px;
  padding: 0.7rem 2.5rem;
  border: none;
  -webkit-appearance: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000;
  background: #ffe402;
  transition: all 0.2s linear;

  &:focus, &:hover {
    outline: none;
    color: #fff;
    background: #000;
  }

  &:disabled {
    background: gray;
  }
`;
