import React from 'react';
import { Container } from 'components/common';
import { Wrapper, Flex, Details, TextField } from './styles';

export const Footer = () => (
  <Wrapper>
    <Flex as={Container}>
      <Details>
        <h2 style={{fontWeight:'100', cursor:'default', fontSize:'22px'}}><b>Elektro</b> Huwiler</h2>
      </Details>

      <Details hasRightMargin>
        <TextField>
          Stallikonerstrasse 73 | 8903 Birmensdorf - ZH | <a href="tel: 0447776544">  044 777 65 44  </a><br/>
          <a href="mailto:info@elektro-huwiler.ch">info@elektro-huwiler.ch</a> | www.elektro-huwiler.ch<br/>
        
        Montag - Freitag  | 7:00 – 12:00 / 13:15 – 17:00
        </TextField>
      </Details>

     
        <br/>
      <Details>
        <TextField >
           <a style={{fontSize: '15px'}} href="/impressum"> Impressum </a>
        </TextField>
      </Details>
    </Flex>
  </Wrapper>
);
