module.exports = {
  defaultTitle: "Elektro Huwiler",
  logo: "ElektroHuwiler",
  author: "ElektroHuwiler",
  url: "www.elektro-huwiler.ch",
  formspree_url: "https://formspree.io/f/mqknveee",
  legalName: "Elektro Huwiler",
  defaultDescription: "Elektro Huwiler",
  googleAnalyticsID: "",
  themeColor: "#ffe402",
  backgroundColor: "#6b63ff",
  address: {
    city: "Birmensdorf",
    region: "Zurich",
    country: "Switzerland",
    zipCode: "8903",
  },
  contact: {
    email: "info@elektro-huwiler.ch",
    phone: "0447776544",
  },
  foundingDate: "1996",
};
